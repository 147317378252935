import { t } from 'react-switch-lang';
import React, { useEffect } from 'react';
import Button from '../../components/Button';
import MainLayout from '../../components/MainLayout';
import { checkSession, useRouter } from '../../components/Router';
import heroImage from '../../public/assets/images/heroes/LandingHero.png';
import styles from '../../styles/Landing.module.scss';
import { events, logAmpEvent } from '../../utils/Amplitude';
import AlgoliaSearch from '../../components/Algolia/AlgoliaSearch';
import { LANGUAGES } from '../../utils/Constants';
import { useScrollToHash } from '../../utils/Helpers';

function Hero() {
  const router = useRouter();
  const routeLink = '/products?id=PAYSAU';
  return (
    <div
      className={styles.heroContainer}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
           url(${heroImage.src})`,
      }}
    >
      <div className={styles.flexContainer}>
        <div className={styles.headlineContainer}>
          <h1 className={`${styles.headlineText} upperCase`}>{t('Landing_Lbl_Headline')}</h1>
          <p className={`${styles.subHeadlineText} upperCase`}>{t('Landing_Lbl_Subheadline')}</p>
          <Button
            className={styles.heroButton}
            ampEvent={events.USER_CLICKED_LANDING_HERO_CTA_BUTTON}
            ampEventProps={{ Text: t('BuyNow_Btn', null, LANGUAGES.EN), Link: routeLink }}
            text={t('BuyNow_Btn')}
            onClick={() => router.push(routeLink)}
            ariaLabel={t('ButtonDescription_BuyPaysafeNow')}
          />
        </div>
      </div>
    </div>
  );
}

function Landing() {
  useEffect(() => {
    const queryParams = (new URL(document.location)).searchParams.get('filter');
    if (queryParams) {
      logAmpEvent(events.USER_VIEWED_MAIN_LANDING_PAGE, { Filters: queryParams });
    } else {
      logAmpEvent(events.USER_VIEWED_MAIN_LANDING_PAGE);
    }
  }, []);
  const scrollTo = useScrollToHash({ '#products': '#Products' });

  return (
    <MainLayout title={t('PageTitle_Landing')} description={t('PageDescription_Landing')}>
      <Hero />
      <hr />
      <div id="Products" className={styles.flexContainer}>
        <div className={styles.landingBodyContainer}>
          <AlgoliaSearch scrollTo={scrollTo} />
        </div>
      </div>
    </MainLayout>
  );
}

export default checkSession(Landing);
export { getStaticPaths, getStaticProps } from '../../utils/DynamicRoutes';
