import aa from 'search-insights';
import { isDevelopment } from './HostingEnv';

const algoliaLogStyle = 'background-color:moccasin; color:black;';

const logToConsole = isDevelopment ? console.log : () => {}; // eslint-disable-line no-console

// Alogolia Analytics are initilized inside of _app.jsx

export const logAlgoliaEvent = (method, event, properties = undefined) => {
  const propertiesObject = {
    eventName: event,
    ...properties,
    index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
  };
  logToConsole(
    `Algolia insight: %c${method}`,
    algoliaLogStyle,
    propertiesObject
  );
  aa(method, propertiesObject);
};

export const algoliaMethods = {
  CLICKED_OBJECT_IDS_AFTER_SEARCH: 'clickedObjectIDsAfterSearch',
  CONVERTED_OBJECT_IDS_AFTER_SEARCH: 'convertedObjectIDsAfterSearch',
  CLICKED_FILTERS: 'clickedFilters',
  VIEWED_FILTERS: 'viewedFilters',
  VIEWED_OBJECT_IDS: 'viewedObjectIDs',
  CONVERTED_OBJECT_IDS: 'convertedObjectIDs',
};

export const algoliaEvents = {
  // landing
  USER_CLICKED_MAIN_LANDING_PRODUCT: 'USER_CLICKED_MAIN_LANDING_PRODUCT',
  USER_CLICKED_MAIN_LANDING_ADD_TO_CART_BUTTON: 'USER_CLICKED_MAIN_LANDING_ADD_TO_CART_BUTTON',
  USER_CLICKED_MAIN_LANDING_PRODUCT_FILTER: 'USER_CLICKED_MAIN_LANDING_PRODUCT_FILTER',
  USER_VIEWED_MAIN_LANDING_PRODUCT_FILTER: 'USER_VIEWED_MAIN_LANDING_PRODUCT_FILTER',

  // product
  USER_VIEWED_DEDICATED_PRODUCT: 'USER_VIEWED_DEDICATED_PRODUCT',
  USER_CLICKED_DEDICATED_PRODUCT_ADD_TO_CART_BUTTON: 'USER_CLICKED_DEDICATED_PRODUCT_ADD_TO_CART_BUTTON',
};
