import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connectStateResults } from 'react-instantsearch-dom';
import { t } from 'react-switch-lang';
import { setScreenReaderAlert } from '../../utils/Accessibility';

let announceResultTimeout = setTimeout;

function announceResult(isFound) {
  clearTimeout(announceResultTimeout);
  announceResultTimeout = setTimeout(() => {
    setScreenReaderAlert(isFound ?
      t('ScreenReaderAlert_Search_Found') :
      t('ScreenReaderAlert_Search_Not_Found'));
  }, 700);
}

function Results({ searchState, searchResults, searching }) {
  // if has searched, announce whether if result is found or not
  if (!searching && searchState.query && searchResults) announceResult(searchResults.nbHits !== 0);

  if (searchResults && searchResults.nbHits === 0) {
    return (
      <div className="noResultsContainer">
        <FontAwesomeIcon
          icon={['far', 'magnifying-glass']}
          className="noResultsIcon"
        />
        <p className="noResultsText">{t('Landing_Lbl_NoResultsFound')}</p>
      </div>
    );
  }

  return null;
}

export default connectStateResults(Results);
