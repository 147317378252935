import React, { useEffect, useRef, useState } from 'react';
import { t, getLanguage } from 'react-switch-lang';
import { connectHits } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import { formatProductDenom, promptGiftCartAlerts, promptPaysafeCartAlerts } from '../../utils/Helpers';
import Link from '../Link';
import { moveFocusTo, replaceProductString, setScreenReaderAlert } from '../../utils/Accessibility';
import { events } from '../../utils/Amplitude';
import { addToCart } from '../../redux/actions/CartActions';
import { Quantity } from '../Field';
import { algoliaEvents, algoliaMethods, logAlgoliaEvent } from '../../utils/AlgoliaInsights';
import { useRouter } from '../Router';
import { cacheHits } from '../../utils/AlgoliaHelpers';

/**
 * @typedef Hit Product in Algolia
 * @type {object}
 * @property {'Payment Voucher'|'Gift Card'|'Gaming Card'} category
 * @property {number[]} denominations
 * @property {number[]} denominationsFee
 * @property {{en: string, fr: string}} description
 * @property {{en: string, fr: string}} imageURL
 * @property {{en: string, fr: string}} name
 * @property {{en: string, fr: string}} redemption
 * @property {{en: string, fr: string}} terms
 * @property {string} kycType
 * @property {string} objectID
 * @property {string} prodCode
 * @property {string} searchTags
 */
/**
 * @typedef Product Selected product code and denomination
 * @type {object}
 * @property {?string} prodCode
 * @property {?number} denom
 */

/**
 * Denomination button.
 * @param {object} props
 * @param {number} props.denom
 * @param {string} props.prodCode
 * @param {string} props.prodName
 * @param {boolean} props.selected
 * @param {(denom: number, selected: boolean) => void} props.onClick
 * @param {string} props.ampEvent
 */
export function Denomination({ id, denom, prodCode, prodName, selected, onClick, location }) {
  return (
    <Button
      id={id}
      ampEvent={events.USER_CLICKED_DENOMINATION_BUTTON}
      ampEventProps={{
        Type: selected ? 'Unselect' : 'Select',
        Product: prodCode,
        Denomination: denom,
        At: location,
      }}
      ariaLabel={
        replaceProductString(t(selected ? 'ButtonDescription_UnselectDenomation' :
          'ButtonDescription_SelectDenomation'), denom, prodName)
      }
      className="denominationButton"
      fill={selected ? 'solid' : 'outline'}
      text={formatProductDenom(denom)}
      onClick={() => onClick(denom, selected)}
    />
  );
}

/**
 * Product image with its denomination buttons.
 * @param {object} props
 * @param {Hit} props.hit
 * @param {Product} props.selectedProduct
 * @param {(product: Product) => void} props.setSelectedProduct
 */
function HitItem({
  hit,
  selectedProduct,
  setSelectedProduct,
  cartInfo,
}) {
  const selected = selectedProduct.prodCode === hit.prodCode;
  const lang = getLanguage();
  const productName = hit.name[lang];
  const dispatch = useDispatch();
  const [addedToCart, setAddedToCart] = useState(false);
  const [showSkipTo, setShowSkipTo] = useState(false);
  const [showCheckoutNow, setShowCheckoutNow] = useState(false);
  const timer = useRef(null);
  const router = useRouter();

  useEffect(() => () => clearTimeout(timer.current), []); // cleanup timeout on dismount

  const onDenomClick = (denom, denomSelected) => {
    // Check if user clicked on the currently selected prodCode and denom
    if (denomSelected) {
      // Unselect product and denom
      setSelectedProduct({ prodCode: null, denom: null, quantity: 1 });
      setScreenReaderAlert(replaceProductString(t('ScreenReaderAlert_UnselectedDenomination'), denom, productName));
      setAddedToCart(false);
      clearTimeout(timer.current);
    } else {
      // Select product and denom
      setSelectedProduct({ prodCode: hit.prodCode, denom, quantity: 1 });
      setScreenReaderAlert(replaceProductString(t('ScreenReaderAlert_SelectedDenomination'), denom, productName));
      setAddedToCart(false);
      clearTimeout(timer.current);
    }
  };

  useEffect(() => {
    if (!selected && showCheckoutNow) setShowCheckoutNow(false);
  }, [selected, showCheckoutNow]);

  return (
    <div className="hitContainer">
      <h3 className="visuallyHidden">{productName}</h3>
      <div className="productOuterContainer">
        <div className={`productInnerContainer ${selected ? 'noBorderBottom' : ''}`}>
          <Link
            href={`/products?id=${hit.objectID}`}
            className="productImageLink"
            onClick={() => {
              logAlgoliaEvent(
                algoliaMethods.CLICKED_OBJECT_IDS_AFTER_SEARCH,
                algoliaEvents.USER_CLICKED_MAIN_LANDING_PRODUCT,
                {
                  objectIDs: [hit.objectID],
                  positions: [hit.order],
                  queryID: hit.__queryID, // eslint-disable-line no-underscore-dangle
                }
              );
            }}
            ampEvent={events.USER_CLICKED_MAIN_LANDING_PRODUCT}
            ampEventProps={{ Product: hit.prodCode }}
            aria-label={t('LinkDescription_LandingProduct').replace('xProduct', productName)}
          >
            <Image
              className="productImage"
              src={hit.imageURL[lang]}
              unoptimized
              alt={productName}
              objectFit="fill"
              width={270}
              height={180}
            />
            <span className="tooltiptext">{t('LinkDescription_LandingProduct').replace('xProduct', productName)}</span>
          </Link>
          <div role="group" className="denominationContainer">
            {hit.denominations.map((denom) => (
              <Denomination
                id={`${hit.prodCode}-${denom}`}
                key={`${hit.prodCode}-${denom}`}
                prodCode={hit.prodCode}
                prodName={productName}
                denom={denom}
                selected={selected && selectedProduct.denom === denom}
                onClick={onDenomClick}
                location="Main Landing Page"
              />
            ))}
          </div>
          {selected && (
          <Quantity
            value={selectedProduct.quantity}
            id={hit.prodCode}
            disabled={hit.prodCode === 'PAYSAU'}
            setValue={(value) => setSelectedProduct({ ...selectedProduct, quantity: value })}
            productName={productName}
            prodCode={hit.prodCode}
            denom={selectedProduct.denom}
            location="Main Landing Page"
          />
          )}
        </div>
        {/* Show Add To Cart Button if prodCode is selected */}
        {selected && (
          <Button
            className="landingCartButton"
            disabled={addedToCart}
            onClick={() => {
              logAlgoliaEvent(
                algoliaMethods.CONVERTED_OBJECT_IDS_AFTER_SEARCH,
                algoliaEvents.USER_CLICKED_MAIN_LANDING_ADD_TO_CART_BUTTON,
                {
                  objectIDs: [hit.objectID],
                  queryID: hit.__queryID, // eslint-disable-line no-underscore-dangle
                }
              );
              if (promptPaysafeCartAlerts(
                selectedProduct.prodCode,
                cartInfo,
                selectedProduct
              )) return;
              if (promptGiftCartAlerts(
                selectedProduct.prodCode,
                cartInfo,
                selectedProduct
              )) return;
              setShowSkipTo(true);
              setAddedToCart(true);
              setShowCheckoutNow(true);
              setScreenReaderAlert(replaceProductString(t('ScreenReaderAlert_Landing_AddedToCart'), selectedProduct.denom, productName, selectedProduct.quantity));
              dispatch(addToCart(
                selectedProduct.prodCode,
                selectedProduct.denom,
                selectedProduct.quantity
              ));
              timer.current = setTimeout(() => setAddedToCart(false), 3000);
            }}
            ampEvent={events.USER_CLICKED_ADD_TO_CART_BUTTON}
            ampEventProps={{
              Product: selectedProduct.prodCode,
              Denomination: selectedProduct.denom,
              Quantity: selectedProduct.quantity,
              At: 'Main Landing Page',
            }}
            ariaLabel={
              replaceProductString(t('ButtonDescription_AddToCart'), selectedProduct.denom, productName, selectedProduct.quantity)
            }
          >
            {addedToCart ?
              (
                <>
                  <FontAwesomeIcon icon={['fas', 'check']} className="addToCartCheck" />
                  <span>{t('AddedToCart_Btn')}</span>
                </>
              ) :
              (
                <span>{t('AddToCart_Btn')}</span>
              )}
          </Button>
        )}
        {showCheckoutNow && (
          <Button
            className="productCartButton"
            fill="outline"
            text={t('CheckoutNow_Btn')}
            onClick={() => router.push('/checkout')}
            ampEvent={events.USER_CLICKED_MAIN_LANDING_CHECKOUT_NOW_BUTTON}
          />
        )}
        {showSkipTo && (
          <Button
            className="productCartButton skipToCartButton"
            fill="outline"
            text={t('ButtonDescription_SkipToCart')}
            onClick={() => moveFocusTo('cart-button')}
            ampEvent={events.USER_CLICKED_MAIN_LANDING_SKIP_TO_CART_BUTTON}
            onBlur={() => setShowSkipTo(false)}
          />
        )}
      </div>
    </div>
  );
}

/**
 * Search results (list of products & their denom) with selected product & denom highlighted.
 * @param {object} props
 * @param {Hit[]} props.hits
 */
function Hits({ hits, scrollTo, searchState }) {
  const [selectedProduct, setSelectedProduct] = useState({
    prodCode: null,
    denom: null,
    quantity: 1,
  });
  const cartInfo = useSelector((state) => state.cart);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    cacheHits(hits);
    if (!loaded && hits.length > 0) setLoaded(true);
  }, [hits]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loaded) scrollTo();
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loaded ? (
        <div
          className={
            `landingProductsList ${searchState.refinementList &&
            searchState.refinementList.category ? 'refined' : ''}`
          }
        >
          <h2 className="visuallyHidden">{t('HiddenLabel_Balance')}</h2>
          {hits.map((hit) => (
            <HitItem
              key={hit.objectID}
              hit={hit}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              cartInfo={cartInfo}
            />
          ))}
        </div>
      ) : (
        <div className="hitsLoadingContainer">
          <p className="hitsLoadingText">{t('Landing_Lbl_Loading')}</p>
        </div>
      ) }
    </div>
  );
}

export default connectHits(Hits);
