import React, { useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import Hits from './Hits';
import SearchBox from './SearchBox';
import RefinementList from './RefinementList';
import Results from './Results';
import { searchClient } from '../../utils/AlgoliaHelpers';

const DEFAULT_PROPS = {
  searchClient,
  indexName: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
};

export default function AlgoliaSearch({ scrollTo }) {
  const [searchState, setSearchState] = useState({});

  return (
    <InstantSearch
      searchState={searchState}
      onSearchStateChange={setSearchState}
      {...DEFAULT_PROPS}
    >
      <div role="group" className="searchContainer">
        <SearchBox />
        <Configure clickAnalytics />
        <RefinementList
          attribute="category"
          transformItems={(items) => items.sort((a, b) => {
            // Tranform function which stops ordering changes on selected refinements
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) return 1;
            if (labelA > labelB) return -1;
            return 0;
          })}
        />
      </div>
      <div className="landingBodyContainer">
        <Hits scrollTo={scrollTo} searchState={searchState} />
        <Results />
      </div>
    </InstantSearch>
  );
}
