import React, { useState, useEffect } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { t } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from '../Router';
import Button from '../Button';
import { PRODUCT_FILTERS } from '../../utils/Constants';
import { setScreenReaderAlert } from '../../utils/Accessibility';
import { events } from '../../utils/Amplitude';
import { algoliaEvents, algoliaMethods, logAlgoliaEvent } from '../../utils/AlgoliaInsights';

/**
 * Filter button.
 * @param {object} props
 * @param {string} props.value filter value
 * @param {string} props.labelKey lang key for filter button text
 * @param {string} props.icon icon for filter button
 * @param {boolean} props.selected indicates whether if this is the currently selected filter
 * @param {(filter: string)=>void} props.setCurrentRefinement function to set selected filter
 */
function RefinementItem({ value, labelKey, icon, selected, setCurrentRefinement }) {
  const router = useRouter();
  const label = t(labelKey);

  const onRefinementClick = () => {
    let newFilter;
    let screenReaderAlertKey;
    let shallowPath;

    if (selected) {
      newFilter = [];
      screenReaderAlertKey = 'ScreenReaderAlert_Landing_RemovedFilter';
      shallowPath = '/';
    } else {
      newFilter = value;
      screenReaderAlertKey = 'ScreenReaderAlert_Landing_AddedFilter';
      shallowPath = { pathname: '/', query: { filter: value } };
      logAlgoliaEvent(
        algoliaMethods.CLICKED_FILTERS,
        algoliaEvents.USER_CLICKED_MAIN_LANDING_PRODUCT_FILTER,
        { filters: [`category:${newFilter}`] }
      );
    }

    setScreenReaderAlert(t(screenReaderAlertKey).replace('xCategory', label));
    setCurrentRefinement(newFilter);
    window.Pace?.ignore(() => router.push(shallowPath, undefined, { shallow: true }));
  };

  return (
    <Button
      ampEvent={selected ?
        events.USER_UNSELECTED_MAIN_LANDING_PRODUCT_FILTER :
        events.USER_SELECTED_MAIN_LANDING_PRODUCT_FILTER}
      ampEventProps={{ Filter: value }}
      ariaLabel={
        t(selected ?
          'ButtonDescription_Landing_RemoveFilterProducts' :
          'ButtonDescription_Landing_FilterProducts')
          .replace('xCategory', label)
        }
      className="refinementButton"
      fill={selected ? 'solid' : 'outline'}
      onClick={onRefinementClick}
    >
      <FontAwesomeIcon
        icon={['fal', icon]}
        className="refinementButtonIcon"
      />
      <span>{label}</span>
    </Button>
  );
}

/**
 * @param {object} props
 * @param {(refinement: string[] | string)=>void} props.refine set refinement for algolia;
 *  for single refinement, the actual string can be passed in instead of an array with single item
 */
function RefinementList({ refine }) {
  const [currentRefinement, setCurrentRefinement] = useState([]);
  const router = useRouter();

  useEffect(() => {
    // Read query parameters on first load
    const queryParams = (new URL(document.location)).searchParams.get('filter');
    if (queryParams) {
      // Check if query parms have appropriate refinement
      const refinedItems = PRODUCT_FILTERS.filter((item) => queryParams.split(',').includes(item.value)).map(({ value }) => value)[0];
      // Set refinement
      if (refinedItems) {
        logAlgoliaEvent(
          algoliaMethods.VIEWED_FILTERS,
          algoliaEvents.USER_VIEWED_MAIN_LANDING_PRODUCT_FILTER,
          { filters: [`category:${refinedItems}`] }
        );
        setCurrentRefinement(refinedItems);
      }
    } else {
      setCurrentRefinement([]);
    }
  }, [router.asPath]); // eslint-disable-line

  useEffect(() => refine(currentRefinement), [refine, currentRefinement]);

  return (
    <>
      {PRODUCT_FILTERS.map(({ value, label, icon }) => (
        <RefinementItem
          key={label}
          value={value}
          labelKey={label}
          icon={icon}
          selected={value === currentRefinement}
          setCurrentRefinement={setCurrentRefinement}
        />
      ))}
      {currentRefinement && currentRefinement.length > 0 && (
        <Button
          className="clearFilterButton"
          fill="solid"
          ariaLabel={t('ButtonDescription_Landing_ClearFilters')}
          ampEvent={events.USER_CLICKED_MAIN_LANDING_CLEAR_FILTERS_BUTTON}
          onClick={() => {
            setCurrentRefinement([]);
            setScreenReaderAlert(t('ScreenReaderAlert_Landing_ClearedFilters'));
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="clearFilterButtonIcon"
          />
          <span>{t('Landing_Filter_ClearFilter')}</span>
        </Button>
      )}
    </>
  );
}

export default connectRefinementList(RefinementList);
