import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import * as Field from '../Field';
import { events, logAmpEvent } from '../../utils/Amplitude';

function SearchBox({ currentRefinement, refine }) {
  return (
    <Field.Search
      inputOnClick={() => { logAmpEvent(events.USER_CLICKED_MAIN_LANDING_SEARCH_BAR); }}
      currentRefinement={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      buttonOnClick={() => {
        refine(currentRefinement);
        logAmpEvent(events.USER_CLICKED_MAIN_LANDING_SEARCH_BUTTON);
      }}
    />
  );
}

export default connectSearchBox(SearchBox);
